<template>

	<div>

		<!-- Table Container Card -->
		<b-card no-body class="mb-0">

			<b-overlay :show="isFetchingContactListSubscribersArray" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

				<div class="m-2">

					<b-row>
						<b-col cols="12" md="12">
							<div class="d-flex justify-content-left">
								<h3 v-if="contactListName">{{ contactListName }}</h3>
							</div>
						</b-col>
					</b-row>
					<!-- Table Top -->
					<hr>
					<b-row>
						<b-col cols="12" md="12">
							<div class="d-flex justify-content-left">
								<h5 v-if="contactStoreType">Storing contacts in {{ contactStoreType }}</h5>
							</div>
						</b-col>

					</b-row>

				</div>

				<b-table ref="refUserListTable" class="position-relative p-1" :items="contactListSubscribersArray" responsive :fields="tableColumnsFieldsArray" primary-key="id" show-empty empty-text="No records found">

					<template #cell(index)="data">
						{{ data.index + 1 }}
					</template>

					<template #cell(date_subscribed)="data">
						<span>{{ data.item.date_subscribed | moment('MMM DD, YYYY') }}</span>
					</template>

					<!-- Column: Actions -->
					<template #cell(actions)="data">

						<div class="text-nowrap">

							<feather-icon v-if="data.item.con_id" icon="TrashIcon" size="21" class="mx-1 cursor-pointer" @click="deleteContactList(data.item.con_id)" />

						</div>

					</template>

				</b-table>

				<b-row v-if="ifRecordFound">
					<b-col cols="12 mb-1 d-flex justify-content-center">
						<b-button type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="" @click="lodeMoreRecords">
							Load More
						</b-button>
					</b-col>
				</b-row>

			</b-overlay>

		</b-card>

	</div>
</template>

<script>
	import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BModal
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import store from '@/store'
	import { ref, onUnmounted } from '@vue/composition-api' 
	import Ripple from 'vue-ripple-directive'
	import useUsersList from './useUsersList'
	import userStoreModule from '../userStoreModule'
	import UserListAddNew from './ContactListAddNew.vue'
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			UserListAddNew,
			BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BModal,
			vSelect,
		},
		directives: {
			Ripple,
		},
		data() {
			return {
				isFetchingContactListSubscribersArray: false,
				tableColumnsFieldsArray: [
					{ key: 'index', label: '#' },
					{ key: 'name', label: 'Name' },
					{ key: 'email', label: 'Email' },
					{ key: 'phone_number', label: 'Phone Number' },
					{ key: 'date_subscribed', label: 'Subscribed Date' },
					{ key: 'actions' },
				],
				contactListName: '',
				contactStoreType: '',
				contactListSubscribersArray: [],
				records_start: 0,
				records_total: 0,
				ifRecordFound: true,
			}
		},
		watch: {
		},
		created() {
			this.getContactList();
		},
		computed: {

		},
		methods: {
			lodeMoreRecords() {
				this.getContactList();
			},
			deleteContactList(contact_id) {
				this.$swal({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, delete it!',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1',
					},
					buttonsStyling: false,
				}).then(result => {

					if (result.value) {

						this.$contactAPI.post('delete-contact-list-subscriber', {
							'auth_id': this.$root.authUserObject.id ?? '',
							'contact_id': contact_id ?? '',
							'contact_list_id': this.$route.params.contact_list_id ?? '',
						}).then((response) => {
							response = response.data;
							if (response.status) {								
								/* REMOVE ROW FROM TABLE : START */
								let index = this.contactListSubscribersArray.findIndex((singleObj) => {
									return singleObj.con_id === contact_id;
								})
								if (index !== -1) this.contactListSubscribersArray.splice(index, 1);
								/* REMOVE ROW FROM TABLE : END */
								
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
			getContactList() {
				this.isFetchingContactListSubscribersArray = true;
				this.$contactAPI.post('fetch-contact-list-subscribers', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'contact_list_id': this.$route.params.contact_list_id ?? '',
					'start': this.records_start,
					'total_page': this.records_total,
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.contactListSubscribersArray.push(...response.contact_list_subscribers);
						this.records_start++;
					} else {
						this.ifRecordFound = response.status
					}
					if (response.contact_list_name) {
						if (this.contactListName != response.contact_list_name) {
							this.contactListName = response.contact_list_name;
						}
					}
					if (response.contact_store_type) {
						if (this.contactStoreType != response.contact_store_type) {
							this.contactStoreType = response.contact_store_type;
						}
					}
					this.isFetchingContactListSubscribersArray = false;
				});
			},
		},
		setup() {
			const USER_APP_STORE_MODULE_NAME = 'app-user'

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
			})

			const {
				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
			} = useUsersList()

			return {
				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>